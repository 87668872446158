import { RichText, Asset } from '@graphcommerce/graphcms-ui'
import { Sizes } from '@graphcommerce/image'
import { Button, Typography, Box, SxProps, Theme } from '@mui/material'
import { normalizeHref } from '../normalizeHref'
import { BannerFragment } from './Banner.gql'

type BannerProps = {
  banner: BannerFragment
  type: 'main' | 'secondary'
  sizes?: Sizes
  loading?: 'eager' | 'lazy' | undefined
  sx?: SxProps<Theme>
}

export function Banner(props: BannerProps) {
  const { banner, type, sizes, loading, sx = [] } = props

  return (
    <Box
      className='bannerBase'
      sx={[
        { backgroundColor: banner.backgroundColor?.hex, borderRadius: '10px' },
        type === 'main'
          ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderRadius: '10px',
            }
          : (theme) => ({
              display: 'grid',
              gridAutoFlow: 'column',
              gridTemplateColumns: '40% 1fr',
              gap: theme.spacings.xxs,
              height: '100%',
            }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box className='asset' sx={{ height: '100%' }}>
        <Asset
          asset={banner.asset}
          loading={loading}
          sizes={sizes}
          quality={90}
          sx={[
            { height: '100%', objectFit: 'cover' },
            type === 'main' ? { borderRadius: '10px 10px 0 0' } : { borderRadius: '10px 0 0 10px' },
          ]}
        />
      </Box>
      <Box
        className='copy'
        sx={[
          { display: 'flex', justifyContent: 'space-between' },
          type === 'main'
            ? (theme) => ({
                p: theme.spacings.xs,
                pr: 0,
                gap: theme.spacings.xs,
                flexDirection: { xs: 'column', md: 'row' },
              })
            : { flexDirection: 'column' },
        ]}
      >
        <RichText
          raw={banner.text?.raw}
          sxRenderer={{
            paragraph: [
              type === 'main'
                ? { typography: 'h1', mb: 0 }
                : (theme) => ({
                    fontSize: { xs: '4.6vw', sm: '2.6vw', md: '1.6vw', xl: '30px' },
                    lineHeight: 1.4,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    p: theme.spacings.sm,
                  }),
            ],
          }}
        />
        <Box
          sx={[
            { alignSelf: 'flex-end' },
            type === 'main' ? null : (theme) => ({ pb: theme.spacings.xs }),
          ]}
        >
          <Button
            href={normalizeHref(banner.buttonLink)}
            variant='cutRight'
            size='small'
            color='primary'
          >
            <Typography
              variant='inherit'
              component='div'
              dangerouslySetInnerHTML={{
                __html: banner.buttonText?.replace('[[', '<span>').replace(']]', '</span>'),
              }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
