import { Row } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { Banner } from '../../Banner/Banner'
import { BannerSlider } from '../../BannerSlider/BannerSlider'
import { RowBannerThreeFragment } from './RowBannerThree.gql'

export function RowBannerThree(props: RowBannerThreeFragment) {
  const { mainBanners, secondaryBanners } = props

  return (
    <Row
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: { md: '2.38fr 1fr' },
        rowGap: theme.spacings.md,
        columnGap: theme.spacings.md,
        mb: 0,
        pt: theme.spacings.md,
        '& .Scroller-root': {
          borderRadius: '10px',
          gridAutoColumns: `calc(100% + 1px)`,
        },
      })}
    >
      {mainBanners && (
        <BannerSlider
          autoScroll
          banners={mainBanners}
          sx={(theme) => ({
            display: 'grid',
            '& .copy': {
              '& > .MuiTypography-root': {
                ...theme.typography.h3,
                lineHeight: { xs: 1.2, md: theme.typography.h3.lineHeight },
              },
            },
          })}
        />
      )}
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridAutoFlow: { sm: 'column', md: 'row' },
          gridAutoColumns: '1fr 1fr',
          gap: theme.spacings.md,
        })}
      >
        {secondaryBanners?.map((banner) => (
          <Banner
            banner={banner}
            key={banner.id}
            type='secondary'
            sx={{
              '& picture img': {
                filter: 'unset',
              },
            }}
            loading='eager'
          />
        ))}
      </Box>
    </Row>
  )
}
