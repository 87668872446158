import { RichText, Asset } from '@graphcommerce/graphcms-ui'
import { Typography, Box, Link, Button, useTheme, Container } from '@mui/material'
import { Banner } from '../../Banner/Banner'
import { RowRecentBlogpostsFragment } from './RowRecentBlogposts.gql'

type RowRecentBlogpostsProps = RowRecentBlogpostsFragment

export function RowRecentBlogposts(props: RowRecentBlogpostsProps) {
  const { featuredPost, pageLinks, title, buttonLink, buttonText } = props

  const theme = useTheme()

  return (
    <Box sx={{ pb: theme.spacings.lg }}>
      <Typography
        variant='h3'
        align='center'
        sx={{
          mb: theme.spacings.sm,
        }}
      >
        Blogs en informatie
      </Typography>
      <Container
        sx={{
          display: 'grid',
          gridTemplateColumns: '2.38fr 1fr',
          gridTemplateRows: '1fr',
          rowGap: theme.spacings.md,
          columnGap: theme.spacings.md,
          mb: 0,
          pt: theme.spacings.xxs,
          '& > div:nth-of-type(n+2)': {
            gridColumnStart: 2,
            gridColumnEnd: 3,
          },
          [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
            '& > div:nth-of-type(1)': {
              gridColumnStart: 1,
              gridColumnEnd: 3,
            },
            '& > div:nth-of-type(n+2)': {
              height: 'unset',
              gridColumnStart: 1,
              gridColumnEnd: 3,
            },
          },
        }}
      >
        {featuredPost?.[0] && (
          <Banner
            banner={featuredPost?.[0]}
            type='main'
            sx={{
              '& .copy': {
                backgroundColor: 'transparent',
                pt: 1,
                textDecoration: 'none',
              },
              '& img': {
                maxHeight: '570px',
                objectFit: 'cover',
              },
            }}
          />
        )}

        <Box
          sx={{
            position: 'relative',
            backgroundColor: { md: theme.palette.primary.contrastText },
            p: { md: theme.spacings.xs },
            pb: { md: theme.spacings.lg },
            borderRadius: { md: '10px' },
            height: '100%',
          }}
        >
          <Box>
            <Typography
              variant='h3'
              sx={{
                mb: theme.spacings.xs,
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              }}
            >
              {title}
            </Typography>
            <Box>
              {pageLinks.map((pageLink, index) => (
                <Link
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${pageLink.url}_${index}`}
                  href={pageLink.url}
                  underline='none'
                  color='inherit'
                  align='center'
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: '25vw 1fr',
                      md: '100px 1fr',
                      lg: '150px 1fr',
                    },
                    textAlign: 'left',
                    alignItems: 'center',

                    '&:not(:last-of-type)': {
                      pb: theme.spacings.sm,
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: '25vw', md: '100px', lg: '150px' },
                      height: { xs: '25vw', md: '100px', lg: '150px' },
                      borderRadius: '10px',
                      overflow: 'hidden',
                      objectFit: 'cover',

                      '& img': {
                        objectFit: 'cover',
                      },
                    }}
                  >
                    {pageLink?.asset && (
                      <Asset asset={pageLink.asset} layout='fill' sizes='150px' unoptimized />
                    )}
                  </Box>

                  <Box
                    sx={{
                      pl: theme.spacings.sm,
                      pr: { lg: theme.spacings.sm },
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: '4',
                      overflow: 'hidden',
                    }}
                  >
                    <RichText
                      raw={pageLink.description?.raw}
                      sxRenderer={{ 'heading-three': { typography: 'h1', mb: 0 } }}
                    />
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: theme.spacings.xxs,
              right: 0,
              mt: theme.spacings.xs,
            }}
          >
            {buttonLink && (
              <Button
                href={buttonLink}
                variant='cutRight'
                color='primary'
                size='small'
                sx={{
                  ml: 'auto',
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                }}
              >
                {buttonText}
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
